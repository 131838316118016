<template>
  <div>
    <div class="topButton">
      <el-button type="success">{{ $t("message.save_and_close") }}</el-button>
    </div>

    <el-form ref="form" :model="form" class="PaymentForm">
      <el-row :gutter="20">
        <el-col :span="11">
          <div class="grid-content bg-purple">
            <el-form-item label="Тип оплаты">
              <el-select v-model="form.paymentType" placeholder="Тип оплаты">
                <el-option label="Zone one" value="shanghai"></el-option>
                <el-option label="Zone two" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Сумма">
              <div class="el-input el-input--mini">
                <money
                  v-model="form.amount"
                  v-bind="money"
                  class="el-input__inner hig"
                ></money>
              </div>
            </el-form-item>

            <el-form-item label="Дата">
              <el-date-picker
                v-model="form.data"
                type="date"
                placeholder="Дата"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-col>
        <!--end col -->

        <el-col :span="11" :offset="2">
          <div class="grid-content bg-purple">
            <el-form-item label="Валюта">
              <el-select v-model="form.currency" placeholder="Валюта">
                <el-option label="Zone one" value="shanghai"></el-option>
                <el-option label="Zone two" value="beijing"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Курс валюты">
              <el-input-number
                v-model="form.num"
                controls-position="right"
                :min="1"
                :max="10"
              ></el-input-number>
            </el-form-item>

            <el-form-item label="Комментарий">
              <el-input
                type="textarea"
                v-model="form.comment"
                maxlength="30"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
      <!--end row  -->
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        paymentType: "",
        amount: "",
        data: "",
        currency: "",
        num: 1,
        comment: "",
      },
    };
  },
  computed: {
    ...mapGetters(["money"]),
  },
  components: {},
};
</script>
<style lang="scss">
.topButton {
  position: absolute;
  top: 17px;
  right: 150px;
}
.PaymentForm {
  padding: 30px;
  .el-form-item__label {
    width: 100%;
    text-align: left;
  }
}
.PaymentForm {
  .el-select,
  .el-input__inner,
  .el-input,
  .el-input-number {
    width: 100% !important;
  }
}
</style>
